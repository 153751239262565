import { imageUrlBuilder } from '../sanity';
import { Project } from '../typings';

type Props = {
  project: Project;
}

function GalleryCard({ project }: Props) {
  return (
    <div
      className=" group relative flex text-6xl cursor-pointer" 
      data-fancybox="gallery"
      data-src={project.mediaUrl}>
      <img
        className="w-full object-cover group-hover:grayscale transition duration-300 ease-in-out"
        src={imageUrlBuilder.image(project.preview).width(300).height(300).url()}
        alt="" />
      <div
        className="absolute opacity-0 group-hover:opacity-70 transition duration-300 ease-in-out group-hover:bg-white w-full h-full">
        <div className="flex flex-col items-center justify-center h-full">
          <p className="text-sm font-bold text-black opacity-100 uppercase">
            {project.name}
          </p>
          <p className="text-sm uppercase">
            {project.info}
          </p>
        </div>
      </div>
    </div>
  )
}

export default GalleryCard