import React, { useEffect } from "react";

// @ts-ignore
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";
import GalleryCard from "./GalleryCard";
import { Carousel, Project } from "../typings";

type Props = {
  delegate: string;
  options?: {};
  carousel: Carousel
}

function Gallery({ delegate, options, carousel }: Props) {
  useEffect(() => {
    const opts = {
      groupAll: true, // Group all items
      infinite: true,
      Toolbar: {
        display: [
          { id: 'prev', position: 'center' },
          { id: 'counter', position: 'center' },
          { id: 'next', position: 'center' },
          'zoom',
          'fullscreen',
          'close',
        ],
      },
    };
    
    Fancybox.bind(delegate, opts);

    return () => {
      Fancybox.destroy();
    };
  }, [delegate, options]);

  return (
    <div className="container mx-auto max-w-6xl px-5">
      <div className="grid grid-cols-2 md:grid-cols-4">
        {carousel.projects.map((item:Project) => {
          return <GalleryCard project={item} key={item._id} />
        })}
      </div>
    </div>
  )
}

export default Gallery