import { PortableText } from '@portabletext/react';
import React from 'react'

type Props = {
  content: any;
}

function Presentation({content}: Props) {
  return (
    <div
      className=" max-w-6xl mx-auto xl:items-center text-left p-5">
      <div
        className="text-md text-justify font-light">
        <PortableText value={content} />
      </div>
    </div>
  )
}

export default Presentation