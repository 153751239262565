import React, { useState } from 'react'
import { useForm, SubmitHandler } from "react-hook-form";
import { ContactInfos } from '../typings';
import emailjs from '@emailjs/browser';

type Props = {
  contactInfos: ContactInfos
}

type Inputs = {
  firstname: string;
  lastname: string;
  email: string;
  message: string;
}

function ContactUs({ contactInfos }: Props) {
  const [messageSent, setMessageSent] = useState<boolean>(false);
  const [messageError, setMessageError] = useState<boolean>(false);
  const {
    register,
    handleSubmit
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = (formData) => {

    const emailjsMessage = {
      to: contactInfos.recipients.join(","),
      subject: contactInfos.object,
      from_name: `${formData.firstname} ${formData.lastname}`,
      reply_to: formData.email,
      message: formData.message
    }
    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID || "",
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID || "",
        emailjsMessage,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        (result) => {
          setMessageSent(true)
          setMessageError(false)
        },
        (error) => {
          setMessageSent(false)
          setMessageError(true)
        });
  };

  return (
    <div className="flex flex-col text-center max-w-6xl justify-center mx-auto items-center pb-10 px-5 md:px-20">
      <h3 className="text-4xl font-family-abril text-black text-center mb-5">Contact</h3>
      <div className=" w-full">
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-2">
          <div className="flex flex-col md:flex-row gap-2">
            <input
              placeholder="Prénom"
              {...register('firstname', { required: true })}
              className="contactInput md:w-1/2" type="text" />
            <input
              placeholder="Nom"
              {...register('lastname', { required: true })}
              className="contactInput md:w-1/2" type="text" />
          </div>
          <input
            placeholder="Email"
            {...register('email', { required: true })}
            className="contactInput" type="email" />
          
          <textarea
            placeholder="Message"
            className="contactInput" 
            rows={5}
            {...register('message', { required: true })} />
          {
            messageSent ?
              messageError ?
              <div className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4" role="alert">
                <p className="font-bold">Erreur</p>
                <p>Votre message n'a pas été envoyé, veuillez réessayer ultérieurement</p>
              </div>
              :
              <div className="bg-teal-100 border-l-4 border-teal-500 text-teal-700 p-4" role="alert">
                <p className="font-bold">Message envoyé</p>
                <p>Merci, nous vous répondrons dans les plus brefs délais</p>
              </div>
            :
            <button
              className="bg-black text-white rounded-md font-bold text-md px-5 py-4">Envoyer</button>    
          }
          
        </form>
      </div>
    </div>
  )
}

export default ContactUs