import { imageUrlBuilder } from '../sanity';
import { Artist } from '../typings'
import ReadMore from './ReadMore';

type Props = {
  artist: Artist
}

function BiographyCard({ artist }: Props) {
  const fullname = artist.firstname + " " + artist.lastname;
  return (
    <article
      className="w-full md:w-1/2 space-y-4 md:space-y-0">
      <div
        className="h-[280px] w-[210px] text-center md:float-left mx-auto md:mr-5 ">
        <img 
          className="h-full w-auto mx-auto"
          src={imageUrlBuilder.image(artist.photo).width(210).height(280).url()}
          alt={fullname} />
      </div>
      <div className="">
        <h3 className="text-2xl font-light font-family-abril text-black text-center md:text-left">{fullname}</h3>
        <div className="text-md text-justify font-light align-top">
          <ReadMore
            text={artist.presentation}
            min={250} max={350} ideal={350} />
        </div>
      </div>
  </article>
  )
}

export default BiographyCard