import sanityClient from "@sanity/client";
import createImageUrlBuilder from "@sanity/image-url";
import ImageUrlBuilder from '@sanity/image-url';

export const config = {
    projectId: process.env.REACT_APP_SANITY_PROJECT_ID || "",
    dataset: process.env.REACT_APP_SANITY_DATASET|| "",
    apiVersion: '2021-03-25',
    useCdn: true
};

export default sanityClient(config);

export const urlFor = (source: any) => {
    createImageUrlBuilder(config).image(source);
}

export const imageUrlBuilder = ImageUrlBuilder(config);