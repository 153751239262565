import React from 'react'
import { ContactInfos } from '../typings'

type Props = {
  contactInfos: ContactInfos
}

function SmallContactUs({ contactInfos }: Props) {
  
  const recipients = contactInfos.recipients?.join(';');

  return (
    <div
      className="space-y-3 text-center mt-5">
      <a 
        href={`mailto:${recipients}?subject=${contactInfos.object}`}
        className="rounded-md bg-black text-white text-lg px-5 py-2" >
        Nous contacter
      </a>
      {
        contactInfos.displayPhoneNumber &&
        <div className="text-lg md:text-xl">
          {contactInfos.phoneNumber}
        </div>
      }      
    </div>
  )
}

export default SmallContactUs