import sanity from "../sanity";
import { Carousel } from "../typings"

const query = `
    *[_type == "carousel" && active == true] { 
        _id, _createdAt, _type, _rev, _updatedAt, name, active,
        projects[]->{_id, _createdAt, _type, _rev, _updatedAt, name,info,"mediaUrl":media.asset->url,preview} 
    }
`
export const fetchRandomCarousel = ():Promise<Carousel> => sanity.fetch(query).then((carousels) => {
    const random:Carousel = carousels[Math.floor(Math.random()*carousels.length)]
    return random;
})