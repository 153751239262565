import Biographies from "./components/Biographies";
import Gallery from "./components/Gallery";
import ContactUs from "./components/ContactUs";
import Header from "./components/Header";
import { fetchSiteInfos } from "./utils/fetchSiteInfos";
import Presentation from "./components/Presentation";
import SmallContactUs from "./components/SmallContactUs";
import { fetchContactInfos } from "./utils/fectContactInfos";
import { fetchArtists } from "./utils/fetchArtists";
import { useQuery } from "react-query";
import { fetchRandomCarousel } from "./utils/fetchCarousel";

function App() {
  const {data:siteInfos, isLoading} = useQuery(["siteInfos"], fetchSiteInfos)
  const {data:artists, isLoading:isLoadingArtists} = useQuery(["artists"], fetchArtists)
  const {data:carousel, isLoading:isLoadingCarousel} = useQuery(["carousel"], fetchRandomCarousel)
  const {data:contactInfos, isLoading:isLoadingContactInfos} = useQuery(["contactInfos"], fetchContactInfos)

  return (
    <div
      className="bg-white text-gray-700 h-screen space-y-14 md:space-y-24 snap-y snap-mandatory">
      <Header />

      { !isLoadingCarousel && carousel && <section 
        id="carousel">
          <Gallery
            delegate="[data-fancybox]"
            carousel={carousel} />
        </section>
      }
      
      { !isLoading && siteInfos && <section
        id="description">
        <Presentation
          content={siteInfos.presentation} />
        
        { !isLoadingContactInfos && contactInfos && 
          <SmallContactUs
            contactInfos={contactInfos} />
        }
      </section>}
      

      { !isLoadingArtists && artists && <section
        id="biographies">
        <Biographies
          artists={artists}  />
        
      </section>}

      { !isLoadingContactInfos && contactInfos && 
        <section
          id="contact">
          <ContactUs contactInfos={contactInfos} />
        </section>
      }
      
    </div>
  );
}

export default App;
