import React from 'react'

type Props = {
  datas?: any
}

function Header({datas}: Props) {
  return (
    <header
      className="p-5 pt-14 flex flex-col items-center justify-between max-w-6xl mx-auto xl:items-center text-center
      ">
      <h1
        className="text-4xl md:text-7xl mt-5 font-family-abril text-black lowercase">dans nos mains</h1>
      <div className="pt-12">
        <span className="text-md md:text-lg">Atelier d'initiation au cinéma d'animation</span><br />
        <span className="italic text-sm md:text-md">découvrir les différentes techniques, inventer une histoire et créer en animation</span>
      </div>
    </header>
  )
}

export default Header