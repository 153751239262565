import React, { useState } from 'react'
import trimText from '../utils/trimText'

type Props = {
  text: string;
  min?: number;
  ideal?: number;
  max?: number;
}

function ReadMore({text, min, ideal, max}: Props) {
  
  const seeMore: string = "En voir plus...";
  const seeLess: string = "Réduire.";

  const [displaySecondary, setDisplaySecondary] = useState<boolean>(false);
  const [readMoreText, setReadMoreText] = useState<string>(seeMore);
  const [primaryText, secondaryText] = trimText(text, min, ideal, max)

  const setDisplay = () => {
    const showMore: boolean = !displaySecondary;
    setDisplaySecondary(showMore);
    setReadMoreText(showMore ? seeLess : seeMore);
  }

  return (
    <>
      {
        !secondaryText ?
          <div className="">
            <span>
              {`${primaryText} ${secondaryText}`}
            </span>
          </div>
        :
          <div>
            <span>{primaryText}</span>
            <span className={displaySecondary ? "" : "hidden"}>{" " + secondaryText}</span>
            <br />
            <span className="">
              {" "}
              <button
                className="mt-2 text-md text-black font-semibold md:hover:underline"
                onClick={() => setDisplay()}>{readMoreText}</button>
            </span>
          </div>
      }
    </>
  )
}

export default ReadMore