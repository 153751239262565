import { Artist } from '../typings'
import BiographyCard from './BiographyCard'

type Props = {
  artists: Artist[]
}

function Biographies({artists}: Props) {
  return (
    <div
      className="flex flex-col md:flex-row max-w-6xl mx-auto md:items-start text-left px-5 gap-10 justify-start">
      {
        artists.map((artist:Artist) => {
          return <BiographyCard
            artist={artist} key={artist._id} />
        })
      }
    </div>
  )
}

export default Biographies